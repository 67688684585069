<template>
    <template v-if="!isLoadingBil">
        <section class="aa-bilingue fade-in">
            <div class="aa-bilingue-portada">
            
                <img :src="bilingue.portada" :alt="bilingue.nombre" class="portada-bilingue" v-on:load="onLoaded" v-show="loaded" />
                <div style="width:100%; height: 327px" v-show="!loaded" class="portada-area">
                    <LoadingVue />
                </div>
            </div>

            <div class="text-start aa-bilingue-info mt-2" v-html="bilingue.introduccion"></div>

            <swiper
                :slidesPerView="'auto'"
                :scrollbar="{ hide: false }"
                :space-between="0"
                :grabCursor="true"
                :breakpoints="{
                    '640': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '768': {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    '1024': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }"
                :modules="modules"
                class="default-slider mt-2 mb-3"
            >
                <!-- <swiper-slide>
                    <div class="contenedor px-1">
                        <router-link :to="{name:'escuela'}">
                        <div class="img-contenedor">
                            <img src="../../../assets/img/fotos/fray.png" alt="IES Fray Bartolomé" title="IES Fray Bartolomé de las Casas">
                        </div>
                        <h4> LA ESCUELA</h4>
                        </router-link>
                    </div>
                </swiper-slide> -->
                <swiper-slide>
                    <div class="contenedor px-1">
                        <router-link :to="{name:'auxiliaresbil'}">
                        <div class="img-contenedor">
                            <img src="../../../assets/img/fotos/conversacion.jpg" alt="Conversación" title="Auxiliares de conversación">
                        </div>
                        <h4>AUXILIAR DE CONVERSACIÓN</h4>
                        </router-link>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="contenedor px-1">
                        <router-link :to="{name:'actividadbil'}">
                        <div class="img-contenedor">
                            <img src="../../../assets/img/fotos/actividades.jpg" alt="Actividades" title="Actividades">
                        </div>
                        <h4> ACTIVIDADES</h4>
                        </router-link>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="contenedor px-1">
                        <router-link :to="{name:'recursobil'}">
                        <div class="img-contenedor">
                            <img src="../../../assets/img/fotos/bilingue_resources.jpg" alt="Recursos" title="Recursos">
                        </div>
                        <h4> Recursos</h4>
                        </router-link>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="contenedor px-1">
                        <router-link :to="{name:'clasesbil'}">
                        <div class="img-contenedor">
                            <img src="../../../assets/img/fotos/clases.jpg" alt="Clases" title="Las clases">
                        </div>
                        <h4> CLASES</h4>
                        </router-link>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="contenedor px-1">
                        <router-link :to="{name:'infobil'}">
                        <div class="img-contenedor">
                            <img src="../../../assets/img/fotos/informacion.jpg" alt="Información" title="Más Información">
                        </div>
                        <h4> INFORMACIÓN</h4>
                        </router-link>
                    </div>
                </swiper-slide>
            </swiper>

            <DescargaDocumento :documentos="bilingue.documentos" :titulo="'Documentación'" class="mt-1" />

        </section>
    </template>
    <template v-else>
        <PrensaSk />
    </template>
</template>

<script>
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { Scrollbar } from "swiper"

import useBilin from '../composables/useBilin'

import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/swiper.min.css'
import { defineAsyncComponent } from '@vue/runtime-core'

import PrensaSk from '../components/skeleton/PrensaSk.vue'

export default {
    components: { 
        Swiper, 
        SwiperSlide, 
        LoadingVue: defineAsyncComponent( () => import('../components/Loading.vue')), 
        DescargaDocumento: defineAsyncComponent( () => import('../components/Area/DescargaDocumento.vue')),
        PrensaSk,
        },
    setup() {
        const { isLoadingBil, loaded, bilingue, getBasico } = useBilin()
        getBasico()
        return {
            isLoadingBil,
            bilingue,
            loaded,
            modules: [ Scrollbar ],
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-bilingue
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-portada
        display: flex
        flex-direction: column
        .portada-bilingue
            order:1
            width: 100%
            margin-bottom: 0px
        @include breakpoint(md)
            height: 390px
            overflow: hidden
            img
                position: relative
                top: -100px
    &-info
        padding-left:2rem
        padding-right:2rem
        @include breakpoint(lg)
            padding-left:3rem
            padding-right:3rem
    .contenedor
        .img-contenedor
            width: 100%
            height: 118px
            img
                width: 100%
                object-fit: cover
                height: 100%
        h4
            width: 100%
            margin-block-start: 0.3rem

.swiper-slide
    width: 200px

.swiper
    @include breakpoint(md)
        height: 200px
</style>